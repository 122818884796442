.footer {
  display: grid;
  place-items: center;
  padding: 20px;
  background: var(--first-color-second);
  color: #000;
}
.footer h2 {
  font-size: 1.5rem;
  margin-bottom: 1.6rem;
  color: var(--first-color-lighter);
}
.footer-social a i {
  margin-left: 10px;
  text-align: center;
  background-color: #fff;
  padding: 6px;
  border-radius: 50%;
}

.footer-social a i:hover {
  background-color: var(--first-color);
  color: var(--first-color-lighter);
}
.copyright {
  padding: 20px;
  background: var(--main-color);
  text-align: center;
  color: var(--text-color);
}
