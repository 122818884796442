.contact_container {
  row-gap: 3rem;
}

.contact_information {
  display: flex;
  margin-bottom: var(--mb-2);
}

.contact_icon {
  font-size: 2rem;
  color: var(--first-color);
  margin-right: var(--mb-0-75);
}

.contact_title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
}

.contact_subtitle {
  font-size: var(--small-font-size);
  color: var(--text-color-light);
}

.contact_content {
  background-color: var(--input-color);
  border-radius: 0.5rem;
  padding: 0.75rem 1rem 0.25rem;
}

.contact_label {
  font-size: var(--small-font-size);
  color: var(--title-color);
}

.contact_input {
  width: 100%;
  background-color: var(--input-color);
  color: var(--text-color);
  font-family: var(--body-color);
  font-size: var(--normal-font-size);
  border: none;
  outline: none;
}

