.services_container {
  gap: 1.5rem;
  grid-template-columns: repeat(2, 1fr);
}

.services_content {
  position: relative;
  background-color: var(--container-color);
  padding: 3.5rem 0.5rem 1.25rem 1.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px rbba(0, 0, 0, 0.15);
  transition: 0.3s;
  align-items: center;
  text-align: center;
  border-radius: 0.5rem;
  box-shadow: 0 2px 7px rgb(14 55 54 / 15%);
}

.services_content:hover {
  border-bottom: 2px solid var(--first-color);
}

.services_icon {
  display: block;
  font-size: 1.5rem;
  margin-bottom: var(--mb-1);
}

.services_title {
  font-size: var(--h3-font-size);
  margin-bottom: var(--mb-1);
  font-weight: var(--font-medium);
}
.services_button {
  color: var(--text-color);
}
.services_button:hover {
  transform: translateX(0.35rem);
}

.services_modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1rem;
  z-index: var(--z-modal);
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}

.services_modal-content {
  position: relative;
  background-color: var(--container-color);
  padding: 1.5rem;
  border-radius: 0.5rem;
}

.services_modal-services {
  row-gap: 1rem;
}

.services_modal-service {
  display: flex;
}

.services_modal-title {
  font-size: var(--h3-font-size);
  font-weight: var(--font-medium);
  margin-bottom: var(--mb-1-5);
}

.services_modal-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  color: var(--first-color);
  cursor: pointer;
}

.services_modal-icon {
  color: var(--first-color);
  margin-right: var(--mb-0-25);
}

.active_modal {
  opacity: 1;
  visibility: visible;
}
