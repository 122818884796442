/*==================== NAV ====================*/
.nav {
  height: 3rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav_logo,
.nav_toggle,
.nav_close {
  color: var(--title-color);
}

.nav_logo {
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: -1px;
  display: inline-flex;
  align-items: center;
  column-gap: 0.5rem;
  transition: 0.3s;
}

.nav_logo:hover {
  color: var(--first-color);
}

.nav_toggle {
  display: inline-flex;
  font-size: 1.25rem;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .nav_menu {
    position: fixed;
    background-color: var(--container-color);
    width: 80%;
    height: 100%;
    top: 0;
    right: -100%;
    box-shadow: -2px 0 4px hsla(var(--hue-color), 24%, 15%, 0.1);
    padding: 4rem 0 0 3rem;
    border-radius: 1rem 0 0 1rem;
    transition: 0.3s;
    z-index: var(--z-fixed);
  }
  .nav_list {
    flex-direction: column;
  }
}

.nav_close {
  font-size: 1.5rem;
  position: absolute;
  top: 1rem;
  right: 1.25rem;
  cursor: pointer;
}

.nav_list {
  display: flex;
  row-gap: 1.5rem;
}

.nav_link {
  color: var(--title-color);
  font-weight: var(--font-medium);
  transition: 0.3s;
}

.nav_link:hover {
  color: var(--first-color);
  cursor: pointer;
}

.show_menu {
  right: 0;
}

.active_link {
  position: relative;
}

.active_link::after {
  content: "";
  position: absolute;
  bottom: -0.5rem;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: var(--first-color);
}

.scroll_header {
  box-shadow: 0 1px 4px hsla(var(--hue-color), 4%, 15%, 0.1);
}
