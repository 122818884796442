.skills_container {
  row-gap: 0;
}

.skills_header {
  display: flex;
  align-items: center;
  margin-bottom: var(--mb-2-5);
  cursor: pointer;
}

.skills_icon,
.skills_arrow {
  font-size: 2rem;
  color: var(--first-color);
}

.skills_icon {
  margin-right: var(--mb-0-75);
}

.skills_title {
  font-size: var(--h3-font-size);
}

.skills_subtitle {
  font-size: var(--small-font-size);
  color: var(--text-color-light);
}

.skills_list {
  row-gap: 1.5rem;
  padding: 2.5rem;
  box-shadow: 0 2px 7px rgb(14 55 54 / 15%);
}

.skills_arrow {
  margin-left: auto;
  transition: 0.4s;
}

.skills_titles {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--mb-0-5);
}

.skills_name {
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
}

.skills_bar,
.skills_percentage {
  height: 5px;
  border-radius: 0.25rem;
}

.skills_bar {
  background-color: var(--text-color-light);
}

.skills_percentage {
  display: block;
  background-color: var(--first-color);
}

.skills_close .skills_list {
  height: 0;
  padding: 0;
  overflow: hidden;
}

.skills_open .skills_list {
  height: max-content;
  margin-bottom: var(--mb-2-5);
}

.skills_open .skills_arrow {
  transform: rotate(-180deg);
}
